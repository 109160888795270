import {Pipe, PipeTransform} from '@angular/core'
import {formatPhone} from "../../../formatters/src/lib/format-phone"

@Pipe({
  name: 'phoneNumber',
  standalone: true,
})
export class PhoneNumberPipe implements PipeTransform {
  transform(value: string | number): string {
    return formatPhone(value)
  }
}
