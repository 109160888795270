export function formatPhone(no?: number | string): string {
  // const phone = [...(no.toString())]
  if (!no) { return '' }
  const phoneStr = no.toString()
  const phone = Array.from(phoneStr)
  let res: string
  switch (phone.length) {
    case 8: {
      // e.g. 79123456 -> 079 123 456
      res = `0${phone[0]}${phone[1]} ${phone[2]}${phone[3]}${phone[4]} ${phone[5]}${phone[6]}${phone[7]}`
      break
    }
    case 9: {
      // e.g. 791234567 -> 079 123 45 67
      res = `0${phone[0]}${phone[1]} ${phone[2]}${phone[3]}${phone[4]} ${phone[5]}${phone[6]} ${phone[7]}${phone[8]}`
      break
    }
    case 11: {
      // e.g. 41791234567 -> +41 79 123 45 67
      res = `+${phone[0]}${phone[1]} ${phone[2]}${phone[3]} ${phone[4]}${phone[5]}${phone[6]} ${phone[7]}${phone[8]} ${phone[9]}${phone[10]}`
      break
    }
    case 12: {
      // e.g. 410791234567 -> +41 79 123 45 67
      res = `+${phone[0]}${phone[1]} ${phone[3]}${phone[4]} ${phone[5]}${phone[6]}${phone[7]} ${phone[8]}${phone[9]} ${phone[10]}${phone[11]}`
      break
    }
    default: {
      res = phoneStr
      break
    }
  }
  return res
}
